import React ,{ useEffect } from 'react'
import axios from 'axios'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { App } from 'antd'


let ResInterceptors: React.FC = () => {
  const { message } = App.useApp();
  const [searchParams, setSearchParams] = useSearchParams();
  const yc_id = searchParams.get('yc_id')
  const navigate = useNavigate()

  useEffect(() => {
    if(yc_id) {
      sessionStorage.setItem('yc_id', yc_id)
    }
  }, [yc_id])

  useEffect(() => {
    init()
  }, [])
  
  const init = () => {
    axios.interceptors.request.use((config:any) => {

      return config
    }, err => {
      return Promise.reject(err)
    })
  
    axios.interceptors.response.use((response) => {
      if(response.data.code !== 1 && !response.data.success) {
        message.warning(response.data.msg)
      }else if(response.data.success !== undefined && response.data.success !== true && response.data.code) {
        message.warning(response.data.msg)
      }
      return response.data;
    }, err => {
      return Promise.reject(err)
    })
  }
  return null
}

export default ResInterceptors



