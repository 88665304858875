import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom'
import ResInterceptors from '@/components/ResInterceptors'
import AuthRouter from "@/routers/utils/authRouter";
import Router from "@/routers/index";
import reportWebVitals from './reportWebVitals';
import { AliveScope } from 'react-activation'
import {
  StyleProvider,
  legacyLogicalPropertiesTransformer,
} from "@ant-design/cssinjs";
import { App, ConfigProvider } from 'antd'
import "@/styles/common.scss";
import "@/styles/reset.scss";
import './index.css';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <div style={{ minWidth: 1200 }}>
    <BrowserRouter basename="/">
      <AliveScope>
        <StyleProvider
          hashPriority="high" //要把where删除掉 详细看这个链接 https://ant.design/docs/react/compatible-style-cn
          transformers={[legacyLogicalPropertiesTransformer]}
        >
          <App>
            <ResInterceptors />
            <AuthRouter>
              <Router  />
            </AuthRouter>
          </App>
        </StyleProvider>
      </AliveScope>
    </BrowserRouter>
  </div>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
