import React from 'react'
import { Navigate, useRoutes } from "react-router-dom";
import lazyLoad from "@/routers/utils/lazyLoad";
import { LayoutIndex } from "@/routers/constant";
import { RouteObject } from "@/routers/interface";

export const rootRouter: RouteObject[] = [
	{
		element: <LayoutIndex />,
		children: [
			{
				path: "/",
				element: lazyLoad(React.lazy(() => import("@/pages/Home"))),
				meta: {
					requiresAuth: true,
					title: "首页",
					key: "home"
				}
			},
			{
				path: '/ksty',
				element: lazyLoad(React.lazy(() => import("@/pages/Ksty"))),
				meta: {
					requiresAuth: true,
					title: "快速体验",
					key: "ksty"
				}
			},
			{
				path: '/yhsc',
				element: lazyLoad(React.lazy(() => import("@/pages/Yhsc"))),
				meta: {
					requiresAuth: true,
					title: "用户手册",
					key: "yhsc"
				}
			},
			{
				path: '/sharevideo',
				element: lazyLoad(React.lazy(() => import("@/pages/Home/Sharevideo"))),
				meta: {
					requiresAuth: true,
					title: "视频分享",
					key: "sharevideo"
				}
			}
		]
	}
];

const Router = () => {
	const routes = useRoutes(rootRouter);
	return routes;
};

export default Router;
