export default {
	'客户管理': '客户管理',
	'搜索': '搜索',
	"标签(可多选)": "标签(可多选)",
	"价格模板(可多选)": "价格模板(可多选)",
	"暂无数据": '暂无数据',
	"导出": '导出',
	"新增": '新增',
	"批量询价": "批量询价",
	"询价": "询价",
	"操作成功": "操作成功",
	'详情': "详情",
	"分享商品": "分享商品",
	"不分享商品": "不分享商品",
	'序号': '序号',
	'客户名称': '客户名称',
	'已认证': '已认证',
	'报价中': '报价中',
	'订单': '订单',
	'标签': '标签',
	'人员': '人员',
	'状态': '状态',
	'操作': '操作',
	'正常': '正常',
	'禁用': '禁用',
	'编辑': '编辑',
	'客户类型': '客户类型',
	"请输入公司税号": "请输入公司税号",
	"公司税号": "公司税号",
	"请输入客户名称": "请输入客户名称",
	"请输入客户ID": "请输入客户ID",
	"客户ID": "客户ID",
	"请选择客户类型": "请选择客户类型",
	"修理厂": "修理厂",
	"汽配商": '汽配商',
	"保险公司": "保险公司",
	"个人": "个人",
	"其他": "其他",
	'支付方式': '支付方式',
	'请选择支付方式': '请选择支付方式',
	'在线支付': '在线支付',
	'货到付款': '货到付款',
	'挂账': '挂账',
	'价格模版': '价格模版',
	"省市区": '省市区',
	"请选择地址": '请选择地址',
	'详细地址': '详细地址',
	"请输入详细地址": "请输入详细地址",
	'联系人': '联系人',
	"请输入联系人": "请输入联系人",
	'联系电话': '联系电话',
	"请输入联系电话": "请输入联系电话",
	'客户审核待通过': '客户审核待通过',
	'客户审核被拒绝': '客户审核被拒绝',
	'未认证': '未认证',
	'认证状态': '认证状态',
	'客户状态': '客户状态',
	'公司名片': '公司名片',
	'上传': '上传',
	'营业执照': '营业执照',
	'确认': '确认',
	'取消': '取消',
	'logo图片': 'logo图片',
	'门店照片': '门店照片',
	
	'微信头像': "微信头像",
	'微信昵称': '微信昵称',
	"手机号码": "手机号码",
	"最后一次询价时间": '最后一次询价时间',
	"加入时间": '加入时间',
	"变更": '变更',
	"确认解绑": '确认解绑',
	"解绑": '解绑',
	"更多": '更多',
	"开单": '开单',
	"打印": '打印',
	"收款": '收款',
	"报价": "报价",
	"报价待认领": "报价待认领",
	"译码": "译码",
	"认领": '认领',
	"询价人": "询价人",
	"接听人": "接听人",
	"接听时间": "接听时间",
	"创建人": "创建人",
	"译码时间": "译码时间",
	"报价时间": "报价时间",
	"当前操作人": "当前操作人",
	"公司": "公司",
	"实付金额": "实付金额",
	"订单金额": "订单金额",
	"商品数量": "商品数量",
	"车型": "车型",
	"报价员": "报价员",
	"待报价": "待报价",
	"已报价-已锁定": "已报价-已锁定",
	"待认领": "待认领",
	"待译码": "待译码",
	"已取消": "已取消",
	"已报价-待确认": "已报价-待确认",
	"已删除": "已删除",
	"待发货": "待发货",
	"部分发货": "部分发货",
	"待收货": "待收货",
	"已发货": "已发货",
	"已完成": "已完成",
	"译码员": "译码员",
	"来源": "来源",
	"创建时间": "创建时间",
	"订单号": "订单号",
	"专票": "专票",
	"普票": "普票",
	
	'请输入授信金额(¥)': '请输入授信金额(¥)',
	"授信挂账额度": "授信挂账额度",
	"挂账金额": "挂账金额",
	"暂无跟进记录": "暂无跟进记录",
	"未合作原因": "未合作原因",
	'无意向': '无意向',
	"合作意向": "合作意向",
	"写跟进": "写跟进",
	"请选择": "请选择",
	"图片/视频": "图片/视频",
	"最多3张": "最多3张",
	"输入内容…": "输入内容…",
	"暂无": "暂无",
	"客户详细地址": "客户详细地址",
	"供应商详细地址": "供应商详细地址",
	"未活跃天数": "未活跃天数",
	"供应品牌": "供应品牌",
	"供应商类型": "供应商类型",
	"供应商状态": "供应商状态",
	"基本信息": "基本信息",
	"对账单": "对账单",
	"订单列表": "订单列表",
	"账户余额": "账户余额",
	"授信额度": "授信额度",
	"成交订单": "成交订单",
	"成交率": "成交率",
	"当前报价单": "当前报价单",
	"备注": "备注",
	"供应商": "供应商",
	"客户": "客户",
	"更新成功": "更新成功",
	'已成功设置审核状态': '已成功设置审核状态',
	'销售价': '销售价',
	'号码': '号码',
	'风险用户提示,该用户曾绑定多家公司': '风险用户提示,该用户曾绑定多家公司',
	'风险': '风险',
	'管理员': '管理员',
	'管': '管',
	"小程序商城": '小程序商城',
	"h5": 'h5',
	'WEB商城': 'WEB商城',
	'微信抓单': '微信抓单',
	'企业微信': '企业微信',
	'APP': 'APP',
	'头像': '头像',
	'岗位': '岗位',
	'注册时间': '注册时间',
	'注册公司名称': '注册公司名称',
	'查询次数': '查询次数',
	'用户状态': '用户状态',
	'审核状态': '审核状态',
	'已通过': '已通过',
	'未通过': '未通过',
	'同意/拒绝': '同意/拒绝',
	'拒绝': '拒绝',
	'同意': '同意',
	"请输入客户名字或者简拼": '请输入客户名字或者简拼',
	"新建客户": "新建客户",
	"切换公司": "切换公司",
	"分享状态": '分享状态',
	"分享": "分享",
	"不分享": "不分享",
	"编辑岗位": "编辑岗位",
	"商城审核": "商城审核",
	"自动通过": "自动通过",
	"人工审核": "人工审核",
	"总共数量": "总共数量",
	"申请时间": "申请时间",
	"图片": "图片",
	"认证公司名称": "认证公司名称",
	"修改成功": "修改成功",
	"报价模版": "报价模版",
	"偏好设置": "偏好设置",
	"功能": "功能",
	"报价列表": "报价列表",
	'*根据客户关联的价格模版，使用对应报价模版；若没有关联则使用系统默认模版。': '*根据客户关联的价格模版，使用对应报价模版；若没有关联则使用系统默认模版。',
	'自定义配件': '自定义配件',
	'税额设置': "税额设置",
	'挂账额度设置': "挂账额度设置",
	"普通发票开票点数": "普通发票开票点数",
	"请输入普通发票开票点数": "请输入普通发票开票点数",
	"增值税专用发票点数": "增值税专用发票点数",
	"请输入增值税专用发票点数": "请输入增值税专用发票点数",
	"请输入授信金额": "请输入授信金额",
	"*所有新增客户挂帐额度，将默认为此金额！": "*所有新增客户挂帐额度，将默认为此金额！",
	
	"订单回写中，请等待": "订单回写中，请等待",
	"驾安配": "驾安配",
	"分配单": "分配单",
	"操作时间": "操作时间",
	"待分配": "待分配",
	"待报价-待认领": "待报价-待认领",
	"报价回写成功": "报价回写成功",
	"报价回写失败": "报价回写失败",
	"已售后": "已售后",
	"车辆确认": "车辆确认",
	"数据录入": "数据录入",
	"维修方案": "维修方案",
	"分配": "分配",
	"是": "是",
	"否": "否",
	"单据列表": "单据列表",
	"订单分配": "订单分配",
	"商户": "商户",
	"选择商户": "选择商户",
	"询价列表": "询价列表",
	"列表": "列表",
	"待商家处理": "待商家处理",
	"实退金额": "实退金额",
	"退款金额": "退款金额",
	"售后数量": "售后数量",
	"售后单号": "售后单号",
	"待处理": "待处理",
	"已同意": "已同意",
	"已拒绝": "已拒绝",
	"供应商名称": "供应商名称",
	"已报价": "已报价",
	"上询列表": "上询列表",
	"确认删除订单?": "确认删除订单?",
	"比价": "比价",
	"查看": "查看",
	"含税": "含税",
	"包裹": "包裹",
	"删除成功": "删除成功",
	"发货时间": "发货时间",
	"单号": "单号",
	"送货骑手": "送货骑手",
	"发货信息": "发货信息",
	"发货凭证": "发货凭证",
	"收货凭证": "收货凭证",
	"ERP开单": "ERP开单",
	"发货": "发货",
	"买家备注": "买家备注",
	"收货人信息": "收货人信息",
	"订单信息": "订单信息",
	"收货人": "收货人",
	"收货地址": "收货地址",
	"件数": "件数",
	"订单备注": "订单备注",
	"下单时间": "下单时间",
	"付款时间": "付款时间",
	"交易流水号": "交易流水号",
	"商品信息": "商品信息",
	"数量": "数量",
	"金额": "金额",
	"申请售后": "申请售后",
	"取消售后": "取消售后",
	"重新申请": "重新申请",
	"商家已同意": "商家已同意",
	"商家已拒绝": "商家已拒绝",
	"售后件数": "售后件数",
	"退款": "退款",
	"确认收货": "确认收货",
	"删除订单": "删除订单",
	"请选择或者输入必填项！": "请选择或者输入必填项！",
	"售后类型": "售后类型",
	"仅退款": "仅退款",
	"退货退款": "退货退款",
	"售后原因": "售后原因",
	"最多可退件数": "最多可退件数",
	"最多可退": "最多可退",
	"售后说明": "售后说明",
	"有原盒": "有原盒",
	"试装过": "试装过",
	"售后图片": "售后图片",
	"请最少选择一个商品发货！": "请最少选择一个商品发货！",
	"请输入完整的收发货信息！": "请输入完整的收发货信息！",
	"发货成功！": "发货成功！",
	"当前状态: 6待发货": "当前状态: 6待发货",
	"零件名称": "零件名称",
	"OE号": "OE号",
	"数量/单位": "数量/单位",
	"品质/品牌": "品质/品牌",
	"分公司": "分公司",
	"物流": "物流",
	"请填写物流名称": "请填写物流名称",
	"请填写物流单号": "请填写物流单号",
	"请填写送货骑手姓名": "请填写送货骑手姓名",
	"请输入送货骑手联系方式": "请输入送货骑手联系方式",
	"发": "发",
	"请输入发件人姓名": "请输入发件人姓名",
	"省/市/区": "省/市/区",
	"请输入发件人联系方式": "请输入发件人联系方式",
	"请输入发件人详细地址": "请输入发件人详细地址",
	"请输入收件人姓名": "请输入收件人姓名",
	"请输入收件人联系方式": "请输入收件人联系方式",
	"请输入收件人详细地址": "请输入收件人详细地址",
	"发货单信息": "发货单信息",
	"请填写件数": "请填写件数",
	"请填写发货单备注": "请填写发货单备注",
	"上传发货凭证": "上传发货凭证",
	"打印发货单": "打印发货单",
	"收货成功！": "收货成功！",
	"当前状态: 8待收货": "当前状态: 8待收货",
	"上传收货凭证": "上传收货凭证",
	"全部": "全部",
	"售后详情": "售后详情",
	"已成功发起售后申请，请耐心等待商家处理！": "已成功发起售后申请，请耐心等待商家处理！",
	"商家已同意,挂账金额已退回！": "商家已同意,挂账金额已退回！",
	"商家已同意，钱款原路退回！": "商家已同意，钱款原路退回！",
	"商家已拒绝，请联系商家协商！": "商家已拒绝，请联系商家协商！",
	"已取消售后申请！": "已取消售后申请！",
	"申请金额": "申请金额",
	"商家已同意，钱款原路退回": "商家已同意，钱款原路退回",
	"操作人": "操作人",
	"已拒绝，订单恢复正常": "已拒绝，订单恢复正常",
	"已取消，订单恢复正常": "已取消，订单恢复正常",
	"实退": "实退",
	"拒绝售后": "拒绝售后",
	"输入拒绝理由": "输入拒绝理由",
	"应付金额": "应付金额",
	"挂账支付": "挂账支付",
	"复制成功！": "复制成功！",
	"产地不唯一，请先选择产地": "产地不唯一，请先选择产地",
	"开单成功": "开单成功",
	"复制": "复制",
	"下载": "下载",
	"销售清单": "销售清单",
	"电话": "电话",
	"投诉电话": "投诉电话",
	"传真": "传真",
	"地址": "地址",
	"购货单位": "购货单位",
	"购货日期": "购货日期",
	"打印时间": "打印时间",
	"税号": "税号",
	"商品编码": "商品编码",
	"零件名": "零件名",
	"报价品质": "报价品质",
	"产地": "产地",
	"出库数": "出库数",
	"售价": "售价",
	"急件": "急件",
	"营业员": "营业员",
	"制单": "制单",
	"合计": "合计",
	"客户备注": "客户备注",
	"合 计": "合 计",
	"付款": "付款",
	'托收': '托收',
	'现金': '现金',
	'收货付款': '收货付款',
	'外地代收': '外地代收',
	'市内代收': "市内代收",
	'汇款': '汇款',
	"人民币大写": "人民币大写",
	"包装员": "包装员",
	"发货员": "发货员",
	"购货收货人": "购货收货人",
	"送及经手人": "送及经手人",
	"请确认 ERP 系统用户名": "请确认 ERP 系统用户名",
	"用户名": "用户名",
	"请选择 ERP 系统用户名": "请选择 ERP 系统用户名",
	"ERP系统用户名错误将无法开单！": "ERP系统用户名错误将无法开单！",
	"发票": "发票",
	"商城": "商城",
	"请选择价格模版": "请选择价格模版",
	"请选择客户": "请选择客户",
	"修改价格模版": "修改价格模版",
	"已选": "已选",
	"修改标签": "修改标签",
	"输入标签名称": "输入标签名称",
	"已添加标签": "已添加标签",
	"添加": "添加",
	"批量价格模版": "批量价格模版",
	"批量标签": "批量标签",
	'日期': '日期',
	'品质': '品质', 
	'单价': '单价', 
	'不含税总价': '不含税总价', 
	'税额': '税额', 
	'手续费': '手续费', 
	'货款': '货款', 
	'售后退款': '售后退款', 
	'实到货款': '实到货款', 
	'货款未结算': '货款未结算', 
	'货款状态': '货款状态', 
	'采购商': '采购商', 
	'采购款': '采购款', 
	'实到采购款': '实到采购款', 
	'采购款未结算': '采购款未结算', 
	'采购款状态': '采购款状态',
	"已有催收单无法生成": "已有催收单无法生成",
	"已结算": "已结算",
	"未结算": "未结算",
	"无法生成催收单！": "无法生成催收单！",
	"该日期内已有生成的催收单，请先取消或客户付款后再生成！": "该日期内已有生成的催收单，请先取消或客户付款后再生成！",
	"已成功批量生成催收单": "已成功批量生成催收单",
	"按所选日期批量生成": "按所选日期批量生成",
	"生成催收单": "生成催收单",
	"货款已结算": "货款已结算",
	"采购款已结算": "采购款已结算",
	"批量生成催收单": "批量生成催收单",
	"催收单": "催收单",
	"该日期内已有生成的催收单，将无法生成可取消或客户付款后再生成！": "该日期内已有生成的催收单，将无法生成可取消或客户付款后再生成！",
	"本期应付货款": "本期应付货款",
	"人民币": "人民币",
	"点击打开小程序支付": "点击打开小程序支付",
	"点击打开网页支付": "点击打开网页支付",
	"制单日期": "制单日期",
	"已成功发送短信": "已成功发送短信",
	"文本": "文本",
	"发短信": "发短信",
	"催收文本": "催收文本",
	"发短信，将发送到公司管理员": "发短信，将发送到公司管理员",
	"发送短信": "发送短信",
	"最大（默认）": "最大（默认）",
	"最小": "最小",
	"平均": "平均",
	"直接定价": "直接定价",
	"商品名称": "商品名称",
	"适用OE": "适用OE",
	"自定义替换OE": "自定义替换OE",
	"适用车型": "适用车型",
	"ERP品质": "ERP品质",
	"质保时间": "质保时间",
	"库存": "库存",
	"ERP价格": "ERP价格",
	"库存价格": "库存价格",
	"4S价": "4S价",
	"参数": "参数",
	"锁定上下架状态?": "锁定上下架状态?",
	"锁定": "锁定",
	"不锁": "不锁",
	"上架": "上架",
	"下架": "下架",
	"ERP产品编码": "ERP产品编码",
	"ERP产品名称": "ERP产品名称",
	"ERP库存": "ERP库存",
	"请先填写内容": "请先填写内容",
	"标签设置成功！": "标签设置成功！",
	"请正确填写参数": "请正确填写参数",
	"商品编辑成功！": "商品编辑成功！",
	"库存价格设置成功！": "库存价格设置成功！",
	"自定义替换OE编辑成功！": "自定义替换OE编辑成功！",
	"图片设置成功！": "图片设置成功！",
	"请最少选择一项！": "请最少选择一项！",
	"仓库": "仓库",
	"商品名称/商品编码": "商品名称/商品编码",
	"OE搜索": "OE搜索",
	"有图": "有图",
	"无图": "无图",
	"无价格": "无价格",
	"有": "有",
	"无": "无",
	"上下架": "上下架",
	"品质质保": "品质质保",
	"批量新增标签": "批量新增标签",
	"锁定上架状态?": "锁定上架状态?",
	"锁定下架状态?": "锁定下架状态?",
	"批量上架": "批量上架",
	"批量下架": "批量下架",
	"删除记录": "删除记录",
	"定价规则": "定价规则",
	"起订量": "起订量",
	"分类": "分类",
	"商品性质": "商品性质",
	"上下架编辑": "上下架编辑",
	"删除商品?": "删除商品?",
	"删": "删",
	"不删": "不删",
	"删除商品": "删除商品",
	"微信扫码上传": "微信扫码上传",
	"推荐图片": "推荐图片",
	"确定": "确定",
	"平台": "平台",
	"操作成功！": "操作成功！",
	"添加商品": "添加商品",
	"添加人员": "添加人员",
	"确认删除?": "确认删除?",
	"删除": "删除",
	"类型": "类型",
	"系统预置": "系统预置",
	"自定义": "自定义",
	"商品": "商品",
	"用户": "用户",
	"新增标签": "新增标签",
	"编辑标签": "编辑标签",
	"标签类型": "标签类型",
	"标签名称": "标签名称",
	"logo": "logo",
	"二级分类": "二级分类",
	"属性": "属性",
	"品牌": "品牌",
	"品类": "品类",
	"品类分类": "品类分类",
	"商品编号": "商品编号",
	"用户名称": "用户名称",
	"订单数量": "订单数量",
	"已选个数": "已选个数",
	"输入商品名称/编码": "输入商品名称/编码",
	"输入用户名称或账号": "输入用户名称或账号",
	"订单管理": "订单管理",
	
	
	"所在城市": "所在城市",
	"最近跟进内容": "最近跟进内容",
	"最近跟进人": "最近跟进人",
	"报价单数": "报价单数",
	"订单数": "订单数",
	"供应商管理": "供应商管理",
	"城市": "城市",
	"空": "空",
	"请选择公司": "请选择公司",
	"产地/品质名称": "产地/品质名称",
	"查询": "查询",
	"请输入oe": "请输入oe",
	"材质": "材质",
	"请输入材质": "请输入材质",
	"ERP价格￥": "ERP价格￥",
	"仓位": "仓位",
	
	"名称/编码/OE": "名称/编码/OE",
	"关联商品": "关联商品",
	"已关联": "已关联",
	"未关联": "未关联",
	"有价格": "有价格",
	"库存导入": "库存导入",
	"库存数量": "库存数量",
	"编辑机洗规则": "编辑机洗规则",
	"执行规则?": "执行规则?",
	"执行": "执行",
	"不执行": "不执行",
	"以下产品已存在，是否覆盖现有产品？": "以下产品已存在，是否覆盖现有产品？",
	"最新报价时间": "最新报价时间",
	"OE号搜索": "OE号搜索",
	
	'已上架': '已上架',
	'已下架': '已下架',
	"当前输入的模版名称已存在！": "当前输入的模版名称已存在！",
	"新增成功！": "新增成功！",
	"删除成功！": "删除成功！",
	"更新成功！": "更新成功！",
	"请确认删除?": "请确认删除?",
	"温馨提示": "温馨提示",
	"未查询到商品信息，请前往ERP库存补充此产品编码对应的适用OE，生成商品编码后，即可进行定价！": "未查询到商品信息，请前往ERP库存补充此产品编码对应的适用OE，生成商品编码后，即可进行定价！",
	"请选择仓库": "请选择仓库",
	"条": "条",
	"原厂替换件": "原厂替换件",
	"自定义替换件": "自定义替换件",
	"新增模版": "新增模版",
	"模版名称": "模版名称",
	"请输入模版名称": "请输入模版名称",
	"基础模版": "基础模版",
	"请选择基础模版": "请选择基础模版",
	"价格设置": "价格设置",
	"请输入数值": "请输入数值",
	"编辑模版": "编辑模版",
	"全部定价商品": "全部定价商品",
	"按比例定价商品": "按比例定价商品",
	"*修改此模版下所有商品！": "*修改此模版下所有商品！",
	"确定要修改吗？": "确定要修改吗？",
	"定价": "定价",
	"当前选中": "当前选中",
	"调比例": "调比例",
	"当前定价": "当前定价",
	"库存价": "库存价",
	"请输入": "请输入",
	"加价": "加价",
	"支持输入负数": "支持输入负数",
	'自定义标签': '自定义标签',
	"折扣": "折扣",
	"上涨": "上涨",
	"下降": "下降",
	"减价": "减价",
	"关闭": "关闭",
	"系统标签": "系统标签",
	"空闲": "空闲",
	"繁忙": "繁忙",
	"定价列表": "定价列表",
	"外询统计": "外询统计",
	"品质质保管理": "品质质保管理",
	"质保时间管理": "质保时间管理",
	"商品管理": "商品管理",
	"库存列表": "库存列表",
	"奔驰": "奔驰",
	
	
	
	"译码列表": "译码列表",
	"挂账授信额度": "挂账授信额度",
	"追加ERP": "追加ERP",
	
	
}